import React from "react";

const NotFound = () => {
  return (
    <div>
      <h1>당신은 잘못된 곳으로 왔습니다. 얼른 바른 길을 찾으세요!</h1>
    </div>
  );
};

export default NotFound;
